body {
  margin: 0;
  font-family: serif;
}

.App {
  text-align: center;
}

.success {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background-color: #0766CD !important;
  text-align: center;
  color: white;
  text-transform: capitalize;
}

.error {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background-color: red !important;
  text-align: center;
  color: white;
  text-transform: capitalize;
}

.header {
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 5px;
  background-color: #6dc5e8 !important;
}

.otpError {
  color: red;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: capitalize;
}

.emailTxtDiv {
  display: inline-block;
  width: 60%;
}

.verifyBtnDiv {
  display: inline-block;
}

.cirlceIconDiv {
  display: inline-block;
  color: skyblue;
  vertical-align: middle;
}

.activeBtn {
  background-color: #0766CD !important;
  color: white !important;
}

.inactiveBtn {
  background-color: #f1f1f1 !important;
  color: black !important;
}

.passwordDiv {
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-top: 1rem;
  width: 100%;
}

.showPasswordIcon {
  margin-left: -2rem;
  margin-top: .7rem;
}

.showPasswordIconDiv{
  margin-left: -1rem;
    margin-top: .7rem;
    width: 20px;
}

.headerPrice
{
  background-color: #0765CD;
    color: white;
    text-align: center;
    font-size: larger;
    font-weight: 600;
}
.fieldContianer
{
  border-bottom: 1px solid blue;
    padding-top: 5px;
    padding-bottom: 5px;
}
.successMessage{
  color: green;
    font-weight: bold;
    text-align: center;
}

.errorMessage{
  color: red;
    font-weight: bold;
    text-align: center;
}

.submitButton{
  background-color: #0766CD !important;
    color: white!important;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px!important;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButtonDiv
{
  text-align: center;
}
.headingImageDiv {
  width: 100vw;
  position: absolute;
}

.bigImage {
  width: 100%;
  height: 20vh;
}
.summary{
  white-space: pre-wrap;
  font-size: large;
  text-align: justify;
}

.fieldName {
  display: inline-block;
  width: 34%;
  font-size: large;
  padding-top: 5px;
  padding-bottom: 5px;
}

.fieldValue {
  display: inline-block;
  width: 65%;
  font-size: large;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
}
.note{
  padding: 10px;
  text-align: justify;
}
.button {
  background-color: #00BEE8!important;
  color: white!important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 2px;
}

.delete {
  background-color: red!important; 
}
.smallLoadingImage{
  height: 50px;
    width: 50px;
}