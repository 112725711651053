/* add google fonts */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* importing the base styles, component styles, and utility classes from Tailwind CSS into this project */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* add custom effect to the table row */
.MuiDataGrid-row:nth-of-type(odd) {
  background-color: #eee6e6;
}

.MuiDataGrid-row:nth-of-type(even) {
  background-color: #ffffff;
}

/* custom effect for the table */
