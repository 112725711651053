.component {
    width: 80%;
}

.componentContainer {
    padding: 1%;
    text-align: center;
}

.saveBtn {
    width: 40%;
}

.header {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 5px;
}