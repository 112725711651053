.component {
    width: 80%;
}

.componentContainer {
    padding: 1%;
    text-align: center;
}

.ddlContainer {
    padding: 1%;
    text-align: center;
}

.ddl {

    width: 80%;
    font-size: large !important;
    color: grey !important;

}

.uploadFormContainer {
    padding: 4%;
    text-align: center;
}

.bikeImage {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    border: 3px solid;
}


.bikeImageDiv {
    width: 45%;
    max-width: 300px;
    display: inline-block;
}

.bikeDetailContainer {
    padding: 20px;
}

.bikeDetailDiv {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-left: 2%;
}

.fieldName {
    display: inline-block;
    width: 34%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fieldValue {
    display: inline-block;
    width: 65%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
    /* font-weight: bold; */
    text-align: left;
}

.link {
    cursor: pointer;
    color: white;
    background-color: #7b90f7;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bolder;
}
.linkContainer :hover {
    color: white;
    background-color: #546ff2;
}

.bikeShortDetailDiv {
    display: inline-block;
    vertical-align: top;
    margin-left: 5%;
}

.header {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.propContainer {
    display: flex;
    flex-direction: column;
}

.headingImageDiv {
    width: 100vw;
    position: absolute;
}

.bigImage {
    width: 100%;
    height: 20vh;
    /* object-fit: cover; */
    /* filter: brightness(50%); */
}

.bikeListcontainer {
    top: 20vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1%;
}

.brand {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: large;
}

.modelYear {
    color: gray;

}

.price {
    font-size: xx-large;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px
}

.city {
    display: inline-block;
    font-size: larger;
    text-transform: uppercase;
}

.locationIcon {
    display: inline-block;
}

.linkContainer {
    padding: 10px;
    margin-top: 10px;
}
.bikeLabel{
    display: flex;
    justify-content: space-between;
}
.updateImage {
    width: 30%;
    height: 100px;
    border-radius: 2px;
    border: 3px solid;
}
.mainContainer{
    display: flex;
    justify-content: center; 
    margin-top: 10px;;
}
.button {
    background-color: #00BEE8!important;
    color: white!important;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 2px;
}

.delete {
    background-color: red!important; 
}
