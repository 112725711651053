.component {
    width: 80%;
}

.componentContainer {
    padding: 1%;
    text-align: center;
}

.ddlContainer {
    padding: 1%;
    text-align: center;
}

.ddl {

    width: 80%;
    font-size: large !important;
    background-color: #00BEE8 !important;
    height: 50px;
    color: #0C4C5D;

}

.errorMessage {
    color: red !important;
    display: inline-block !important;
    margin-left: 15%;
}

.container {
    position: relative;
    text-align: center;
    height: 100vh;
    background-color: #0665CD;
    font-family: sans-serif;
}

.loginRegister {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
}

.loginLink {
    color: #fff;
    text-decoration: none;
}

.heading {
    top: 5vh;
    position: absolute;
    display: flex;
    width: 100vw;
    flex-direction: column;
    color: #fff;
}

.heading1 {
    font-size: 20px;
    font-weight: 600;
}

.heading2 {
    font-size: 50px;
    font-weight: 600;
}

.formContainer {
    display: flex;
    top: 20vh;
    position: absolute;
    width: 100vw;
    flex-direction: column;
    align-items: center;
}

.customerForm {
    width: 90vw;
    background-color: #00BEE8;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.saveBtn {
    /* width: 50vw; */
    top: 2vh;
    position: relative;
    background-color: #00BEE8;
    border-radius: 15px;
    background-color: green;
    font-weight: 600;

}

.newReagistrationHeading {
    color: #0665CD;
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
}
.passwordContainer {
    padding: 1%;
    justify-content: center;
    display: flex;
}
.component {
    width: 80%;
}

.componentContainer {
    padding: 1%;
    text-align: center;
}

.ddlContainer {
    padding: 1%;
    text-align: center;
}

.ddl {

    width: 80%;
    font-size: large !important;
    color: grey !important;

}

.uploadFormContainer {
    padding: 4%;
    text-align: center;
}

.bikeImage {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    border: 3px solid;
}


.bikeImageDiv {
    width: 45%;
    display: inline-block;
}

.bikeDetailContainer {
    padding: 20px;
}

.bikeDetailDiv {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-left: 5%;
}

.fieldName {
    display: inline-block;
    width: 34%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fieldValue {
    display: inline-block;
    width: 65%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
    /* font-weight: bold; */
    text-align: left;
}

.button {
    background-color: #00BEE8!important;
    color: white!important;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 2px;
}

.delete {
    background-color: red!important; 
}

.bikeShortDetailDiv {
    display: inline-block;
    vertical-align: top;
    margin-left: 5%;
}

.header {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.propContainer {
    display: flex;
    flex-direction: column;
}

.headingImageDiv {
    width: 100vw;
    position: absolute;
}

.bigImage {
    width: 100%;
    height: 20vh;
    /* object-fit: cover; */
    /* filter: brightness(50%); */
}

.bikeListcontainer {
    top: 20vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1%;
}

.brand {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: large;
}

.modelYear {
    color: gray;

}

.price {
    font-size: xx-large;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px
}

.city {
    display: inline-block;
    font-size: larger;
    text-transform: uppercase;
}

.locationIcon {
    display: inline-block;
}

.linkContainer {
    display: flex ;
    justify-content: space-between;
}