.component {
    width: 80%;
}

.componentContainer {
    padding: 1%;
    text-align: center;
}

.ddlContainer {
    padding: 1%;
    text-align: center;
}

.ddl {

    width: 80%;
    font-size: large !important;
    color: grey !important;

}

.errorMessage {
    color: red !important;
    display: inline-block !important;
    margin-left: 15%;
}

.saveBtn {
    width: 40%;
}

.mainDiv {
    padding: 5%;
    text-align: center;
}

.header {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.uploadFormContainer {
    padding: 4%;
    text-align: center;
}

.headingImageDiv {
    width: 100vw;
    position: absolute;
}

.bigImage {
    width: 100%;
    height: 20vh;
} 

.workshopListcontainer {
    top: 20vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1%;
}

.propContainer {
    display: flex;
    flex-direction: column;
}

.workshopDetailContainer {
    padding: 20px;
}

.workshopImage {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    border: 3px solid;
}


.workshopImageDiv {
    width: 45%;
    display: inline-block;
}

.workshopDetailDiv {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-left: 5%;
}

.name {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: large;
}

.yearsOld {
    color: gray;

}

.city {
    display: inline-block;
    font-size: larger;
    text-transform: uppercase;
}

.linkContainer {
    padding: 10px;
}

.link {
    cursor: pointer;
    color: blue;
}

.locationIcon {
    display: inline-block;
    padding-top: 10px;
}

.fieldName {
    display: inline-block;
    width: 34%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fieldValue {
    display: inline-block;
    width: 65%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
}

.starActive {
    color: #FFD700;
    display: inline-block;
}
.tabWidth{
    min-width: 350px !important;
}
.detailContainer {
    min-width: 80% !important;
}
.updateImage {
    width: 30%;
    height: 100px;
    border-radius: 2px;
    border: 3px solid;
}